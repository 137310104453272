import { useSelector } from "react-redux";
import { RootState } from "store/reducers";
import { useMemo } from "react";
import {
    CollectionVideoResponse,
    EmailRedemptionResponse,
    EntitlementResponse,
    PasswordRedemptionResponse,
    PurchaseResponse
} from "@switcherstudio/player-api-client";
import useCollectionWithVideos from "./useCollectionWithVideos";

export const useGatedContent = (
    specificBroadcast?: CollectionVideoResponse
) => {
    /* Get all the necessary data from the store */
    const creator = useSelector((s: RootState) => s.playerCreator);
    const creatorCustomers = useSelector((s: RootState) => s.creatorCustomers);

    const { catalog } = useSelector((s: RootState) => s.catalogState);
    const { currentCollectionVideo, isEmbed, parentFrame } = useSelector(
        (s: RootState) => s.videoSession
    );
    const { collection } = useCollectionWithVideos();
    const broadcastToCheck = useMemo(
        () => specificBroadcast ?? currentCollectionVideo,
        [specificBroadcast, currentCollectionVideo]
    );

    function hasMatchingHigherLevelProductID(
        entitlement: EntitlementResponse,
        entitlements: EntitlementResponse[]
    ): boolean {
        return entitlements.some(
            (e) =>
                (e?.details?.type === "Collection" ||
                    e?.details?.type === "Catalog") &&
                e?.details?.productId === entitlement?.details?.productId
        );
    }

    /* Catalog Gating */
    const { catalogIsGated, catalogIsEntitled, catalogRequiresPurchase } =
        useMemo(() => {
            return {
                catalogIsGated: catalog?.isGated ?? false,
                catalogIsEntitled: catalog?.isEntitled ?? false,
                catalogRequiresPurchase:
                    catalog?.isGated && !catalog?.isEntitled
            };
        }, [catalog]);

    const {
        catalogPurchaseEntitlements,
        catalogEmailEntitlements,
        catalogPasswordEntitlements,
        catalogInvoices,
        catalogEmailRedemptions,
        catalogPasswordRedemptions
    } = useMemo(() => {
        const catalogAndCollectionEntitlements =
            catalog?.entitlements ?? collection?.entitlements ?? [];
        const catalogPurchaseEntitlements =
            catalogAndCollectionEntitlements.filter(
                (entitlement) =>
                    entitlement?.details?.type === "Catalog" &&
                    entitlement?.details?.redemptionType === "Purchase"
            );

        const catalogEmailEntitlements =
            catalogPurchaseEntitlements.length > 0
                ? []
                : catalogAndCollectionEntitlements.filter(
                      (entitlement) =>
                          entitlement?.details?.type === "Catalog" &&
                          entitlement?.details?.redemptionType === "Email"
                  );
        const catalogPasswordEntitlements =
            catalogAndCollectionEntitlements.filter(
                (entitlement) =>
                    entitlement?.details?.type === "Catalog" &&
                    entitlement?.details?.redemptionType === "Password"
            );

        const catalogInvoices = catalogPurchaseEntitlements
            .flatMap((entitlement) => entitlement.purchases)
            .filter((invoice) => !!invoice);

        const catalogEmailRedemptions = catalogEmailEntitlements
            .flatMap((entitlement) => entitlement.emailRedemptions)
            .filter((redemption) => !!redemption);

        const catalogPasswordRedemptions = catalogPasswordEntitlements
            .flatMap((entitlement) => entitlement.passwordRedemptions)
            .filter((redemption) => !!redemption);

        return {
            catalogPurchaseEntitlements,
            catalogEmailEntitlements,
            catalogPasswordEntitlements,
            catalogInvoices,
            catalogEmailRedemptions,
            catalogPasswordRedemptions
        };
    }, [catalog?.entitlements, collection?.entitlements]);

    /* Player Gating */
    const { playerIsGated, playerIsEntitled, playerRequiresPurchase } = useMemo(
        () => ({
            playerIsGated: collection?.isGated ?? false,
            playerIsEntitled: collection?.isEntitled ?? false,
            playerRequiresPurchase:
                collection?.isGated && !collection?.isEntitled
        }),
        [collection]
    );

    const {
        playerPurchaseEntitlements,
        playerEmailEntitlements,
        playerPasswordEntitlements,
        playerInvoices,
        playerEmailRedemptions,
        playerPasswordRedemptions
    } = useMemo(() => {
        const playerEntitlements = collection?.entitlements ?? [];

        const playerPurchaseEntitlements = playerEntitlements.filter(
            (entitlement) =>
                entitlement?.details?.type === "Collection" &&
                entitlement?.details?.redemptionType === "Purchase"
        );

        const playerEmailEntitlements =
            playerPurchaseEntitlements.length > 0
                ? []
                : playerEntitlements.filter(
                      (entitlement) =>
                          entitlement?.details?.type === "Collection" &&
                          entitlement?.details?.redemptionType === "Email"
                  );

        const playerPasswordEntitlements = playerEntitlements.filter(
            (entitlement) =>
                entitlement?.details?.type === "Collection" &&
                entitlement?.details?.redemptionType === "Password"
        );

        const playerInvoices = playerPurchaseEntitlements
            ?.flatMap((entitlement) => entitlement.purchases)
            ?.filter((invoice) => !!invoice);

        const playerEmailRedemptions = playerEmailEntitlements?.flatMap(
            (entitlement) => entitlement.emailRedemptions
        );

        const playerPasswordRedemptions = playerPasswordEntitlements?.flatMap(
            (entitlement) => entitlement.passwordRedemptions
        );

        return {
            playerPurchaseEntitlements,
            playerEmailEntitlements,
            playerPasswordEntitlements,
            playerInvoices,
            playerEmailRedemptions,
            playerPasswordRedemptions
        };
    }, [collection?.entitlements]);

    /* Playlist Broadcast Gating */
    const hasFeaturedVideoAndIdleStateIsFeatured = useMemo<boolean>(
        () =>
            (collection?.details?.idleState === "SelectVideo" &&
                broadcastToCheck?.broadcast?.details?.id ===
                    collection?.details?.idleBroadcastId) ??
            false,
        [collection, broadcastToCheck]
    );

    const {
        playlistBroadcastPurchaseEntitlements,
        playlistBroadcastEmailEntitlements,
        playlistBroadcastPasswordEntitlements,
        playlistBroadcastInvoices,
        playlistBroadcastEmailRedemptions,
        playlistBroadcastPasswordRedemptions,
        playlistBroadcastIsGatedIndividually
    } = useMemo((): {
        playlistBroadcastPurchaseEntitlements: EntitlementResponse[];
        playlistBroadcastEmailEntitlements: EntitlementResponse[];
        playlistBroadcastPasswordEntitlements: EntitlementResponse[];
        playlistBroadcastInvoices: PurchaseResponse[];
        playlistBroadcastEmailRedemptions: EmailRedemptionResponse[];
        playlistBroadcastPasswordRedemptions: PasswordRedemptionResponse[];
        playlistBroadcastIsGatedIndividually: boolean;
    } => {
        const isGatedIndividually =
            !!broadcastToCheck?.entitlements?.length &&
            broadcastToCheck?.entitlements.length > 0;

        if (hasFeaturedVideoAndIdleStateIsFeatured)
            return {
                playlistBroadcastPurchaseEntitlements: [],
                playlistBroadcastEmailEntitlements: [],
                playlistBroadcastPasswordEntitlements: [],
                playlistBroadcastInvoices: [],
                playlistBroadcastEmailRedemptions: [],
                playlistBroadcastPasswordRedemptions: [],
                playlistBroadcastIsGatedIndividually: isGatedIndividually
            };

        const allEntitlements: EntitlementResponse[] =
            broadcastToCheck?.entitlements ?? [];

        const videoEntitlements = allEntitlements.filter(
            (entitlement) => entitlement?.details?.type === "Video"
        );
        const higherLevelEntitlements = videoEntitlements.filter(
            (entitlement) =>
                entitlement?.details?.redemptionType === "Purchase" &&
                hasMatchingHigherLevelProductID(entitlement, allEntitlements)
        );
        const purchaseEntitlements: EntitlementResponse[] =
            videoEntitlements.filter(
                (entitlements) =>
                    !higherLevelEntitlements.includes(entitlements) &&
                    entitlements?.details?.redemptionType === "Purchase"
            );

        const emailEntitlements: EntitlementResponse[] =
            videoEntitlements.filter(
                (entitlements) =>
                    higherLevelEntitlements.length <= 0 &&
                    entitlements?.details?.redemptionType === "Email" &&
                    purchaseEntitlements.length <= 0
            );

        const passwordEntitlements: EntitlementResponse[] =
            videoEntitlements.filter(
                (entitlements) =>
                    entitlements?.details?.redemptionType === "Password"
            );

        const invoices =
            purchaseEntitlements
                ?.flatMap((entitlement) => entitlement.purchases)
                ?.filter((invoice) => !!invoice) ?? [];

        const emailRedemptions =
            emailEntitlements
                ?.flatMap((entitlement) => entitlement.emailRedemptions)
                ?.filter((invoice) => !!invoice) ?? [];

        const passwordRedemptions =
            passwordEntitlements
                ?.flatMap((entitlement) => entitlement.passwordRedemptions)
                ?.filter((invoice) => !!invoice) ?? [];

        return {
            playlistBroadcastPurchaseEntitlements: purchaseEntitlements,
            playlistBroadcastEmailEntitlements: emailEntitlements,
            playlistBroadcastPasswordEntitlements: passwordEntitlements,
            playlistBroadcastInvoices: invoices,
            playlistBroadcastEmailRedemptions: emailRedemptions,
            playlistBroadcastPasswordRedemptions: passwordRedemptions,
            playlistBroadcastIsGatedIndividually: isGatedIndividually
        };
    }, [
        broadcastToCheck?.entitlements,
        hasFeaturedVideoAndIdleStateIsFeatured
    ]);

    const { playlistBroadcastIsGated, playlistBroadcastIsEntitled } = useMemo(
        () => ({
            playlistBroadcastIsGated: broadcastToCheck
                ? broadcastToCheck.isGated
                : false,
            playlistBroadcastIsEntitled: broadcastToCheck
                ? broadcastToCheck.isEntitled
                : true
        }),
        [broadcastToCheck]
    );

    const playlistBroadcastRequiresPurchase = useMemo(
        () =>
            playlistBroadcastIsGatedIndividually &&
            !playlistBroadcastIsEntitled,
        [playlistBroadcastIsEntitled, playlistBroadcastIsGatedIndividually]
    );

    /* Gather all available entitlements */
    const customerEmail = useMemo(
        () =>
            creatorCustomers[creator?.details?.stripeAccountId ?? ""]?.ticket
                ?.email,
        [creator, creatorCustomers]
    );

    const chargesEnabled = useMemo(
        () => creator?.details?.chargesEnabled,
        [creator?.details]
    );
    const gatedContentDisabled = useMemo<boolean>(
        () => isEmbed && !parentFrame,
        [isEmbed, parentFrame]
    );

    const userHasSubscription = useMemo<boolean>(() => {
        return (
            catalogInvoices?.length > 0 ||
            playerInvoices?.length > 0 ||
            playlistBroadcastInvoices?.length > 0
        );
    }, [
        catalogInvoices?.length,
        playerInvoices?.length,
        playlistBroadcastInvoices?.length
    ]);

    const {
        availablePurchaseEntitlements,
        availableEmailEntitlements,
        availablePasswordEntitlements
    } = useMemo(() => {
        const catalogProductIds = new Set();
        const playerProductIds = new Set();

        const filteredEntitlements = (entitlements: EntitlementResponse[]) => {
            return entitlements.filter((entitlement) => {
                const productId = entitlement.details.productId;

                if (entitlement.details.type === "Catalog") {
                    catalogProductIds.add(productId);
                    return true;
                }

                if (entitlement.details.type === "Collection") {
                    if (catalogProductIds.has(productId)) {
                        return false;
                    }
                    playerProductIds.add(productId);
                    return true;
                }

                if (entitlement.details.type === "Video") {
                    if (
                        catalogProductIds.has(productId) ||
                        playerProductIds.has(productId)
                    ) {
                        return false;
                    }
                    return true;
                }

                return true;
            });
        };

        // Once we know if/how the user has access, we can return early
        if (catalogInvoices?.length > 0) {
            // User has access so no entitlements need to be considered
            return {
                availablePurchaseEntitlements: [],
                availableEmailEntitlements: [],
                availablePasswordEntitlements: []
            };
        }

        // User has access to the catalog via password or email redemption
        if (
            catalogEmailRedemptions?.length > 0 ||
            catalogPasswordRedemptions?.length > 0
        ) {
            // But we still give them the option to purchase
            return {
                availableEmailEntitlements: [],
                availablePasswordEntitlements: [],
                availablePurchaseEntitlements: filteredEntitlements(
                    catalogPurchaseEntitlements
                )
            };
        }

        // User has purchased the player
        if (playerInvoices?.length > 0) {
            // They have access to the player, but can still purchase/redeem access to the catalog
            return {
                availablePurchaseEntitlements: filteredEntitlements(
                    catalogPurchaseEntitlements
                ),
                availableEmailEntitlements: filteredEntitlements(
                    catalogEmailEntitlements
                ),
                availablePasswordEntitlements: filteredEntitlements(
                    catalogPasswordEntitlements
                )
            };
        }

        // User has access to the player via password or email redemption
        if (
            playerEmailRedemptions?.length > 0 ||
            playerPasswordRedemptions?.length > 0
        ) {
            // But we still give them the option to purchase the player and catalog
            // or redeem access to the catalog via password or email
            return {
                availableEmailEntitlements: filteredEntitlements(
                    catalogEmailEntitlements
                ),
                availablePasswordEntitlements: filteredEntitlements(
                    catalogPasswordEntitlements
                ),
                availablePurchaseEntitlements: filteredEntitlements([
                    ...catalogPurchaseEntitlements,
                    ...playerPurchaseEntitlements
                ])
            };
        }

        // User has purchased the broadcast
        if (playlistBroadcastInvoices?.length > 0) {
            // They have access to the broadcast, but can still purchase/redeem access to the catalog and player
            return {
                availablePurchaseEntitlements: filteredEntitlements([
                    ...catalogPurchaseEntitlements,
                    ...playerPurchaseEntitlements
                ]),
                availableEmailEntitlements: filteredEntitlements([
                    ...catalogEmailEntitlements,
                    ...playerEmailEntitlements
                ]),
                availablePasswordEntitlements: filteredEntitlements([
                    ...catalogPasswordEntitlements,
                    ...playerPasswordEntitlements
                ])
            };
        }

        // User has access to the broadcast via password or email redemption
        if (
            playlistBroadcastEmailRedemptions?.length > 0 ||
            playlistBroadcastPasswordRedemptions?.length > 0
        ) {
            // But we still give them the option to purchase the broadcast,
            // or get access to the catalog or player via password or email
            return {
                availablePurchaseEntitlements: filteredEntitlements([
                    ...catalogPurchaseEntitlements,
                    ...playerPurchaseEntitlements,
                    ...playlistBroadcastPurchaseEntitlements
                ]),
                availableEmailEntitlements: filteredEntitlements([
                    ...catalogEmailEntitlements,
                    ...playerEmailEntitlements
                ]),
                availablePasswordEntitlements: filteredEntitlements([
                    ...catalogPasswordEntitlements,
                    ...playerPasswordEntitlements
                ])
            };
        }

        // The user has no access, so they can see all levels of entitlement
        return {
            availablePurchaseEntitlements: filteredEntitlements([
                ...catalogPurchaseEntitlements,
                ...playerPurchaseEntitlements,
                ...playlistBroadcastPurchaseEntitlements
            ]),
            availableEmailEntitlements: filteredEntitlements([
                ...catalogEmailEntitlements,
                ...playerEmailEntitlements,
                ...playlistBroadcastEmailEntitlements
            ]),
            availablePasswordEntitlements: filteredEntitlements([
                ...catalogPasswordEntitlements,
                ...playerPasswordEntitlements,
                ...playlistBroadcastPasswordEntitlements
            ])
        };
    }, [
        catalogInvoices,
        catalogEmailRedemptions,
        catalogPasswordRedemptions,
        playerInvoices,
        playerEmailRedemptions,
        playerPasswordRedemptions,
        playlistBroadcastInvoices,
        playlistBroadcastEmailRedemptions,
        playlistBroadcastPasswordRedemptions,
        catalogPurchaseEntitlements,
        playerPurchaseEntitlements,
        playlistBroadcastPurchaseEntitlements,
        catalogEmailEntitlements,
        playerEmailEntitlements,
        playlistBroadcastEmailEntitlements,
        catalogPasswordEntitlements,
        playerPasswordEntitlements,
        playlistBroadcastPasswordEntitlements
    ]);

    const availableEntitlements = useMemo(() => {
        return [
            ...availablePurchaseEntitlements,
            ...availableEmailEntitlements,
            ...availablePasswordEntitlements
        ];
    }, [
        availablePurchaseEntitlements,
        availableEmailEntitlements,
        availablePasswordEntitlements
    ]);

    return {
        catalogIsGated,
        catalogIsEntitled,
        /** Indicates that the catalog is gated and has not been purchased */
        catalogRequiresPurchase,
        catalogPurchaseEntitlements,
        catalogEmailEntitlements,
        catalogPasswordEntitlements,
        catalogInvoices,
        catalogEmailRedemptions,
        catalogPasswordRedemptions,
        playerIsGated,
        playerIsEntitled,
        /** Indicates that the player is gated and has not been purchased */
        playerRequiresPurchase,
        playerPurchaseEntitlements,
        playerEmailEntitlements,
        playerPasswordEntitlements,
        playerInvoices,
        playerEmailRedemptions,
        playerPasswordRedemptions,
        playlistBroadcastIsGated,
        /** Indicates that the broadcast is gated and has not been purchased */
        playlistBroadcastRequiresPurchase,
        /** Broadcast is gated from its own entitlement, not including player entitlements */
        playlistBroadcastIsGatedIndividually,
        playlistBroadcastIsEntitled,
        playlistBroadcastPurchaseEntitlements,
        playlistBroadcastEmailEntitlements,
        playlistBroadcastPasswordEntitlements,
        playlistBroadcastInvoices,
        playlistBroadcastEmailRedemptions,
        playlistBroadcastPasswordRedemptions,
        customerEmail,
        chargesEnabled,
        gatedContentDisabled,
        userHasSubscription,
        availableEmailEntitlements,
        availablePasswordEntitlements,
        availablePurchaseEntitlements,
        availableEntitlements
    };
};
