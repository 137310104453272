import { useIsMountedRef } from "hooks/useIsMountedRef";
import React, { useCallback, useEffect } from "react";
import { Notification, NotificationType } from "store/notification/types";
import styles from "./NotificationPopover.module.scss";
import CloseIcon from "assets/icons/close.svg?react";
import classNames from "classnames/bind";
const cx = classNames.bind(styles);

const DEFAULT_FADE = 2500;

interface NotificationPopoverProps {
    /** The notification. */
    notification: Notification;
    /** A handler function attached to the notification close button. */
    close: () => void;
    /** Whether this notification should fade or remain */
    shouldFade: boolean;
}

/** A notification popover. */
export const NotificationPopover: React.FC<NotificationPopoverProps> = ({
    notification,
    close,
    shouldFade = true
}: NotificationPopoverProps) => {
    const isMountedRef = useIsMountedRef();

    useEffect(() => {
        if (shouldFade) {
            // if a manual number is set, prefer that
            // Otherwise, errors do not auto-fade and
            // all other messages use DEFAULT_FADE
            let fade = notification.fadeMilliseconds
                ? notification.fadeMilliseconds
                : notification.type === NotificationType.Danger
                ? -1
                : DEFAULT_FADE;

            if (isMountedRef.current && fade > -1) {
                setTimeout(() => close(), fade);
            }
        }
    }, [isMountedRef, notification, close, shouldFade]);

    const handleClickAction = useCallback(() => {
        !!notification.clickAction && notification.clickAction();
        close();
    }, [close, notification]);

    return (
        <div
            className={cx("alert", notification.type, notification.class, {
                "dismissable": !shouldFade
            })}
            role="alert"
        >
            <div className={cx("message")}>
                {notification.message}
                {!!notification.clickText && !!notification.clickAction ? (
                    <a
                        onClick={handleClickAction}
                        className={cx("click-action")}
                    >
                        {notification.clickText}
                    </a>
                ) : null}
            </div>

            {!shouldFade && (
                <button title="Close" className={cx("close")} onClick={close}>
                    <CloseIcon />
                </button>
            )}
        </div>
    );
};
