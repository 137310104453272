import { createSlice, PayloadAction, Reducer } from "@reduxjs/toolkit";
import { CatalogState, CollectionVideosGrouping } from "./types";
import {
    getCatalog,
    getCollection,
    getCollections,
    getCollectionVideosOnDemand,
    getCollectionVideosProcessingPolling,
    getCollectionVideosUpcoming,
    getCollectionVideosUpcomingPolling,
    getSingleBroadcastResponse
} from "./thunks";
import {
    BroadcastDetailsResponse,
    BroadcastResponse,
    CatalogDetailsResponse,
    CatalogResponse,
    CollectionDetailsResponse,
    CollectionResponse,
    CollectionsPagedResponse,
    CollectionVideoResponse,
    CollectionVideosPagedResponse
} from "@switcherstudio/player-api-client";
import { lesser } from "helpers/numbers";

export const DEFAULT_COLLECTIONS_PAGE_SIZE = 2;
export const DEFAULT_COLLECTION_VIDEO_PAGE_SIZE = 11;
const initialPrimaryColor = "#000000";
const initialSecondaryColor = "#FFFFFF";

const customizedKeys = [
    "buttonTextColor",
    "buttonBackgroundsLinksColor",
    "primaryColor",
    "secondaryColor",
    "donateButtonColor",
    "donateButtonTextColor",
    "upcomingBackgroundColor",
    "logoBackgroundColor",
    "playerControlsColor",
    "defaultInteractiveTab",
    "embedBackgroundColor",
    "embedTextColor",
    "interactivePanelBackgroundColor",
    "interactivePanelTextColor",
    "upcomingTextColor",
    "autoOpenInteractiveMode"
];

const omitCustomized = (obj: any) => {
    Object.keys(obj)
        .filter((k) => customizedKeys.some((key) => key === k))
        .forEach((k) => delete obj[k]);
    return obj;
};

const onlyCustomized = (obj: any) => {
    Object.keys(obj)
        .filter((k) => !customizedKeys.some((key) => key === k))
        .forEach((k) => delete obj[k]);
    return obj;
};

const omitNullorUndefined = (obj: any) => {
    Object.keys(obj)
        .filter((k) => obj[k] === null || obj[k] === undefined)
        .forEach((k) => delete obj[k]);
    return obj;
};

const initialState = {
    configuredCatalogId: null,
    configuredCollectionId: null,
    configuredBroadcastId: null,
    catalog: {
        creatorIsValid: true,
        details: {
            buttonBackgroundsLinksColor: initialPrimaryColor,
            buttonTextColor: initialSecondaryColor,
            upcomingBackgroundColor: initialPrimaryColor,
            playerControlsColor: initialSecondaryColor,
            defaultInteractiveTab: "About",
            aspectRatio: undefined,
            embedBackgroundColor: initialSecondaryColor,
            embedTextColor: initialPrimaryColor,
            interactivePanelBackgroundColor: initialSecondaryColor,
            interactivePanelTextColor: initialPrimaryColor,
            upcomingTextColor: initialSecondaryColor,
            autoOpenInteractiveMode: false,
            embeddedDisplay: "DefaultThumbnail"
        } as unknown as CatalogDetailsResponse
    },
    collections: {
        totalPages: 0,
        totalRecords: 0,
        page: 0,
        pageSize: DEFAULT_COLLECTION_VIDEO_PAGE_SIZE,
        collections: [
            {
                creatorIsValid: true,
                details: {
                    primaryColor: initialPrimaryColor,
                    secondaryColor: initialSecondaryColor,
                    donateButtonColor: initialPrimaryColor,
                    donateButtonTextColor: initialSecondaryColor,
                    logoBackgroundColor: initialPrimaryColor,
                    playerControlsColor: initialSecondaryColor,
                    defaultInteractiveTab: "About",
                    aspectRatio: undefined,
                    embedBackgroundColor: initialSecondaryColor,
                    embedTextColor: initialPrimaryColor,
                    interactivePanelBackgroundColor: initialSecondaryColor,
                    interactivePanelTextColor: initialPrimaryColor,
                    upcomingTextColor: initialSecondaryColor,
                    autoOpenInteractiveMode: false,
                    embeddedDisplay: null
                } as unknown as CollectionDetailsResponse
            }
        ]
    } as CollectionsPagedResponse,
    collectionVideosMap: {},
    singleBroadcastResponse: null,
    isCustomized: false,
    hasLoadedCatalog: false,
    hasLoadedCollections: false,
    hasLoadedCollectionVideosOnDemand: false,
    hasLoadedCollectionVideosUpcoming: false,
    hasLoadedSingleBroadcastResponse: false,
    hasLoadingError: false,
    isGatedContentDisabled: true,
    loadedCollectionPageCount: 1
} as CatalogState;

export const catalogState = createSlice({
    name: "Catalog",
    initialState: initialState,
    reducers: {
        clearCatalog: (state) => {
            if (state.isCustomized) {
                return {
                    ...initialState,
                    isCustomized: state.isCustomized,
                    configuredCatalogId: state.configuredCatalogId,
                    catalog: {
                        ...state.catalog,
                        details: onlyCustomized({
                            ...state.catalog.details
                        })
                    },
                    collections: {
                        ...initialState.collections,
                        collections: state.collections.collections?.map(
                            (collection) => ({
                                ...collection,
                                details: onlyCustomized({
                                    ...collection?.details
                                }) as CollectionDetailsResponse
                            })
                        )
                    } as CollectionsPagedResponse,
                    isGatedContentDisabled: state.isGatedContentDisabled,
                    loadedCollectionPageCount: state.loadedCollectionPageCount
                };
            } else {
                return {
                    ...initialState,
                    isGatedContentDisabled: state.isGatedContentDisabled,
                    loadedCollectionPageCount: state.loadedCollectionPageCount
                };
            }
        },
        setConfiguredCatalogId: (
            state,
            { payload }: PayloadAction<string | null>
        ) => {
            state.configuredCatalogId = payload;
        },
        setConfiguredCollectionId: (
            state,
            { payload }: PayloadAction<string | null>
        ) => {
            state.configuredCollectionId = payload;
        },
        setConfiguredBroadcastId: (
            state,
            { payload }: PayloadAction<string | null>
        ) => {
            state.configuredBroadcastId = payload;
        },
        setCatalogDetails: (
            state,
            { payload }: PayloadAction<CatalogDetailsResponse>
        ) => {
            state.catalog.details = {
                ...state.catalog.details,
                ...omitNullorUndefined(payload)
            };
            state.isCustomized = true;
        },
        setCollectionDetails: (
            state,
            { payload }: PayloadAction<CollectionDetailsResponse>
        ) => {
            const targetCollection =
                state.collections.collections?.find(
                    (collection) => collection?.details?.id === payload.id
                ) ??
                ({
                    creatorIsValid: true
                } as CollectionResponse);

            targetCollection.details = {
                ...targetCollection.details,
                ...omitNullorUndefined(payload)
            } as CollectionDetailsResponse;

            state.collections.collections = [
                ...(state.collections.collections?.filter(
                    (collection) =>
                        collection?.details?.id !== payload.id &&
                        !!collection?.details?.id
                ) ?? []),
                targetCollection
            ];
            state.isCustomized = true;
        },
        setIsGatedContentDisabled: (
            state,
            { payload }: PayloadAction<boolean>
        ) => {
            state.isGatedContentDisabled = payload;
        },
        // Only used when the lite player is used as a featured trailer in the catalog (with a preloaded broadcast)
        setHasLoadedSingleBroadcastResponse: (
            state,
            { payload }: PayloadAction<boolean>
        ) => {
            state.hasLoadedSingleBroadcastResponse = payload;
        }
    },
    extraReducers(builder) {
        builder.addCase(getCatalog.fulfilled, (state, action) => {
            //console.log("getCatalog.fulfilled", action.payload);

            let details = action.payload.details;
            if (state.isCustomized) {
                details = omitCustomized(details);
            }

            state.catalog = {
                ...action.payload,
                details: {
                    ...state.catalog.details,
                    // only override detail defaults if they have value
                    ...omitNullorUndefined(details)
                }
            } as CatalogResponse;
            state.hasLoadedCatalog = true;
        });
        builder.addCase(getCollections.fulfilled, (state, action) => {
            if (!action.payload) return;

            state.collections.totalPages = action.payload.totalPages;
            state.collections.totalRecords = action.payload.totalRecords;
            state.collections.page = action.payload.page;
            state.collections.pageSize = action.payload.pageSize;

            state.loadedCollectionPageCount =
                (action.payload.page ?? 0) > state.loadedCollectionPageCount
                    ? action.payload.page ?? 0
                    : state.loadedCollectionPageCount;

            state.collections.collections = [
                ...(state.collections.collections || []),
                ...(action.payload.collections || [])
            ];

            state.hasLoadedCollections = true;

            action.payload.collections?.forEach((collection) => {
                const key = collection.details!.id!;
                state.collectionVideosMap[key] ||= {
                    OnDemand: {} as CollectionVideosPagedResponse,
                    Upcoming: {} as CollectionVideosPagedResponse,
                    Processing: [] as BroadcastResponse[]
                } as CollectionVideosGrouping;
                if (collection.featuredTrailer) {
                    state.collectionVideosMap[key].OnDemand.items = [
                        {
                            broadcast: collection.featuredTrailer,
                            details: {
                                id: collection.featuredTrailer.details?.id
                            }
                        } as CollectionVideoResponse,
                        ...(state.collectionVideosMap[key].OnDemand.items || [])
                    ];
                }
            });
        });

        // Single collection used for player
        builder.addCase(getCollection.fulfilled, (state, action) => {
            let details = action.payload.details;

            if (state.isCustomized) {
                details = omitCustomized(details);
            }

            state.collections.collections = [
                {
                    ...state.collections.collections?.[0],
                    ...action.payload,
                    details: {
                        ...state.collections.collections?.[0].details,
                        // only override detail defaults if they have value
                        ...omitNullorUndefined(details)
                    }
                } as CollectionResponse
            ];

            // inject the featured trailer
            const key = action.meta.arg.collectionId!;
            state.collectionVideosMap[key] ||= {
                OnDemand: {} as CollectionVideosPagedResponse,
                Upcoming: {} as CollectionVideosPagedResponse,
                Processing: [] as BroadcastResponse[]
            } as CollectionVideosGrouping;
            if (action.payload.featuredTrailer) {
                state.collectionVideosMap[key].OnDemand.items = [
                    {
                        broadcast: action.payload.featuredTrailer,
                        details: {
                            id: action.payload.featuredTrailer.details?.id
                        }
                    } as CollectionVideoResponse,
                    ...(state.collectionVideosMap[key].OnDemand.items || [])
                ];
            }

            state.hasLoadedCollections = true;
        });

        // OnDemand Pending
        builder.addCase(
            getCollectionVideosOnDemand.pending,
            (state, action) => {
                const key = action.meta.arg.collectionId!;

                state.collectionVideosMap[key] ||= {
                    OnDemand: {} as CollectionVideosPagedResponse,
                    Upcoming: {} as CollectionVideosPagedResponse
                } as CollectionVideosGrouping;

                let nextPageSize = DEFAULT_COLLECTION_VIDEO_PAGE_SIZE;
                const { totalRecords, items } =
                    state.collectionVideosMap[key].OnDemand;

                if (totalRecords && items) {
                    nextPageSize = lesser(
                        totalRecords - items.length,
                        DEFAULT_COLLECTION_VIDEO_PAGE_SIZE
                    );
                }
                state.collectionVideosMap[key].OnDemand.items = [
                    ...(state.collectionVideosMap[key].OnDemand.items || []),
                    ...(new Array<CollectionVideoResponse>(nextPageSize) || [])
                ];
            }
        );

        // OnDemand Fulfilled
        builder.addCase(
            getCollectionVideosOnDemand.fulfilled,
            (state, action) => {
                const key = action.payload.collectionId!;
                state.collectionVideosMap[key] ||= {
                    OnDemand: {} as CollectionVideosPagedResponse,
                    Upcoming: {} as CollectionVideosPagedResponse,
                    Processing: [] as BroadcastResponse[]
                } as CollectionVideosGrouping;

                const collection = state.collections?.collections?.find(
                    (collection) => collection?.details?.id === key
                );

                // Filter out the featured video and gated items (when in compat mode) from the collection items
                action.payload.items = action.payload.items?.filter((item) => {
                    const isFeaturedVideo =
                        collection?.details?.idleState === "SelectVideo" &&
                        item?.details?.broadcastId ===
                            collection?.details?.idleBroadcastId;
                    return (
                        !isFeaturedVideo &&
                        (state.isGatedContentDisabled ? !item?.isGated : true)
                    );
                });

                state.collectionVideosMap[key].OnDemand.totalPages =
                    action.payload.totalPages;
                state.collectionVideosMap[key].OnDemand.totalRecords =
                    action.payload.totalRecords;
                state.collectionVideosMap[key].OnDemand.page =
                    action.payload.page;
                state.collectionVideosMap[key].OnDemand.pageSize =
                    action.payload.pageSize;

                state.collectionVideosMap[key].OnDemand.items = [
                    ...(state.collectionVideosMap[key].OnDemand.items?.filter(
                        (item) => !!item?.details?.id
                    ) || []),
                    ...(action.payload.items || [])
                ];

                state.hasLoadedCollectionVideosOnDemand = true;
            }
        );

        // OnDemand Rejected
        builder.addCase(
            getCollectionVideosOnDemand.rejected,
            (state, action) => {
                const key = action.meta.arg.collectionId!;

                state.collectionVideosMap[key] ||= {
                    OnDemand: {} as CollectionVideosPagedResponse,
                    Upcoming: {} as CollectionVideosPagedResponse,
                    Processing: [] as BroadcastResponse[]
                } as CollectionVideosGrouping;

                state.collectionVideosMap[key].OnDemand.items = [
                    ...(state.collectionVideosMap[key].OnDemand.items?.filter(
                        (item) => !!item?.details?.id
                    ) || [])
                ];

                state.hasLoadedCollectionVideosOnDemand = true;
                state.hasLoadingError = true;
            }
        );

        // Upcoming Pending
        builder.addCase(
            getCollectionVideosUpcoming.pending,
            (state, action) => {
                const key = action.meta.arg.collectionId!;

                state.collectionVideosMap[key] ||= {
                    OnDemand: {} as CollectionVideosPagedResponse,
                    Upcoming: {} as CollectionVideosPagedResponse,
                    Processing: [] as BroadcastResponse[]
                } as CollectionVideosGrouping;

                let nextPageSize = DEFAULT_COLLECTION_VIDEO_PAGE_SIZE;
                const { totalRecords, items } =
                    state.collectionVideosMap[key].Upcoming;

                if (totalRecords && items) {
                    nextPageSize = lesser(
                        totalRecords - items.length,
                        DEFAULT_COLLECTION_VIDEO_PAGE_SIZE
                    );
                }
                state.collectionVideosMap[key].Upcoming.items = [
                    ...(state.collectionVideosMap[key].Upcoming.items || []),
                    ...(new Array<CollectionVideoResponse>(nextPageSize) || [])
                ];
            }
        );

        // Upcoming Fulfilled
        builder.addCase(
            getCollectionVideosUpcoming.fulfilled,
            (state, action) => {
                const key = action.payload.collectionId!;
                state.collectionVideosMap[key] ||= {
                    OnDemand: {} as CollectionVideosPagedResponse,
                    Upcoming: {} as CollectionVideosPagedResponse,
                    Processing: [] as BroadcastResponse[]
                } as CollectionVideosGrouping;

                const collection = state.collections?.collections?.find(
                    (collection) => collection?.details?.id === key
                );

                // Filter out the featured video and gated items (when in compat mode) from the collection items
                action.payload.items = action.payload.items?.filter((item) => {
                    const isFeaturedVideo =
                        collection?.details?.idleState === "SelectVideo" &&
                        item?.details?.broadcastId ===
                            collection?.details?.idleBroadcastId;
                    return (
                        !isFeaturedVideo &&
                        (state.isGatedContentDisabled ? !item?.isGated : true)
                    );
                });

                // Inject live video into ondemand list
                const liveVideo = action.payload.items?.find((item) => {
                    // Filter out all videos that are not live
                    return (
                        item.broadcast?.details?.broadcastStatus === "Active" &&
                        item.broadcast?.videos?.some(
                            (video) =>
                                video.details?.status === "live-inprogress"
                        )
                    );
                });

                if (!!liveVideo) {
                    //console.log("INJECT LIVE VIDEO - INITIAL", liveVideo);
                    state.collectionVideosMap[key].OnDemand.items = [
                        ...(!!liveVideo ? [liveVideo] : []),
                        ...(state.collectionVideosMap[
                            key
                        ].OnDemand.items?.filter(
                            (item) => !!item?.details?.id
                        ) || [])
                    ];
                }

                // Push pending items into processing polling
                const pendingItems =
                    action.payload.items
                        ?.filter((item) => {
                            return (
                                item.broadcast?.details?.broadcastStatus ===
                                    "Ended" &&
                                !item.broadcast?.videos?.[0]?.details
                                    ?.readyToStream &&
                                !!item?.broadcast
                            );
                        })
                        .map((item) => item.broadcast!) ?? [];
                // console.log(
                //     "Add pending items to processing queue",
                //     pendingItems
                // );
                if (pendingItems.length > 0) {
                    state.collectionVideosMap[key].Processing = [
                        ...(state.collectionVideosMap[key].Processing || []),
                        ...pendingItems
                    ];
                }

                state.collectionVideosMap[key].Upcoming.totalPages =
                    action.payload.totalPages;
                state.collectionVideosMap[key].Upcoming.totalRecords =
                    action.payload.totalRecords;
                state.collectionVideosMap[key].Upcoming.page =
                    action.payload.page;
                state.collectionVideosMap[key].Upcoming.pageSize =
                    action.payload.pageSize;

                state.collectionVideosMap[key].Upcoming.items = [
                    ...(state.collectionVideosMap[key].Upcoming.items?.filter(
                        (item) => !!item?.details?.id
                    ) || []),
                    ...(action.payload.items || [])
                ];

                state.hasLoadedCollectionVideosUpcoming = true;
            }
        );

        // Upcoming Rejected
        builder.addCase(
            getCollectionVideosUpcoming.rejected,
            (state, action) => {
                const key = action.meta.arg.collectionId!;

                state.collectionVideosMap[key] ||= {
                    OnDemand: {} as CollectionVideosPagedResponse,
                    Upcoming: {} as CollectionVideosPagedResponse,
                    Processing: []
                } as CollectionVideosGrouping;

                state.collectionVideosMap[key].Upcoming.items = [
                    ...(state.collectionVideosMap[key].Upcoming.items?.filter(
                        (item) => !!item?.details?.id
                    ) || [])
                ];

                state.hasLoadedCollectionVideosUpcoming = true;
                state.hasLoadingError = true;
            }
        );

        // Upcoming Polling Fulfilled
        builder.addCase(
            getCollectionVideosUpcomingPolling.fulfilled,
            (state, action) => {
                const key = action.payload.collectionId!;
                state.collectionVideosMap[key] ||= {
                    OnDemand: {} as CollectionVideosPagedResponse,
                    Upcoming: {} as CollectionVideosPagedResponse,
                    Processing: [] as BroadcastResponse[]
                } as CollectionVideosGrouping;

                const collection = state.collections?.collections?.find(
                    (collection) => collection?.details?.id === key
                );

                // Filter out the featured video and gated items (when in compat mode) from the collection items
                action.payload.items = action.payload.items?.filter((item) => {
                    const isFeaturedVideo =
                        collection?.details?.idleState === "SelectVideo" &&
                        item?.details?.broadcastId ===
                            collection?.details?.idleBroadcastId;
                    return (
                        !isFeaturedVideo &&
                        (state.isGatedContentDisabled ? !item?.isGated : true)
                    );
                });

                const isLiveInjected =
                    state.collectionVideosMap[key].OnDemand.items?.[0]
                        ?.broadcast?.details?.broadcastStatus === "Active";
                const liveVideoFromUpcoming = action.payload.items?.find(
                    (item) => {
                        // Filter out all videos that are not live
                        return (
                            item.broadcast?.details?.broadcastStatus ===
                                "Active" &&
                            item.broadcast?.videos?.some(
                                (video) =>
                                    video.details?.status === "live-inprogress"
                            )
                        );
                    }
                );

                // Update injected live video when live ends.  Sets the status to ended (and videos to not ready), and adds to processing queue
                if (isLiveInjected && !liveVideoFromUpcoming) {
                    if (state.collectionVideosMap[key].OnDemand.items?.[0]) {
                        const currentItem =
                            state.collectionVideosMap[key].OnDemand.items[0];
                        const currentBroadcast = currentItem?.broadcast;

                        // console.log(
                        //     "Set ondemand live video to ended",
                        //     state.collectionVideosMap[key].OnDemand.items[0]
                        // );
                        state.collectionVideosMap[key].OnDemand.items[0] = {
                            ...currentItem,
                            broadcast: {
                                ...currentBroadcast,
                                details: {
                                    ...currentBroadcast?.details,
                                    broadcastStatus: "Ended",
                                    startsAt: new Date(Date.now() - 60000 * 5) // subtract 5 minutes to give buffer to UI calculations
                                } as BroadcastDetailsResponse
                            } as BroadcastResponse
                        };

                        // console.log(
                        //     "Add live video to processing queue",
                        //     state.collectionVideosMap[key].OnDemand.items[0]
                        //         .broadcast
                        // );
                        // Add the video to the processing queue
                        state.collectionVideosMap[key].Processing = [
                            ...(state.collectionVideosMap[key].Processing ||
                                []),
                            state.collectionVideosMap[key].OnDemand.items[0]
                                .broadcast as BroadcastResponse
                        ];
                    }
                }

                // Inject live video into ondemand list
                if (!isLiveInjected && !!liveVideoFromUpcoming) {
                    // console.log(
                    //     "INJECT LIVE VIDEO - POLLING",
                    //     liveVideoFromUpcoming
                    // );
                    state.collectionVideosMap[key].OnDemand.items = [
                        ...(!!liveVideoFromUpcoming
                            ? [liveVideoFromUpcoming]
                            : []),
                        ...(state.collectionVideosMap[
                            key
                        ].OnDemand.items?.filter(
                            (item) => !!item?.details?.id
                        ) || [])
                    ];
                }

                // DEVNOTE: This only works as for the polling scenario we are not leveraging paging.  If we include paging into the player, this will become a lot more complicated and need rework.
                // Replace the full data set
                state.collectionVideosMap[key].Upcoming = action.payload;
            }
        );

        // Processing Polling Fullfilled
        builder.addCase(
            getCollectionVideosProcessingPolling.fulfilled,
            (state, action) => {
                const key = action.meta.arg.collectionId!;
                state.collectionVideosMap[key] ||= {
                    OnDemand: {} as CollectionVideosPagedResponse,
                    Upcoming: {} as CollectionVideosPagedResponse,
                    Processing: [] as BroadcastResponse[]
                } as CollectionVideosGrouping;

                // Filter out ready-to-stream broadcasts
                const stillProcessing = action.payload.filter(
                    (broadcast: BroadcastResponse) => {
                        const isReady = broadcast.videos?.some(
                            (video) => video?.details?.readyToStream === true
                        );

                        if (isReady) {
                            // Only update if it already exists in OnDemand
                            const existingIndex =
                                state.collectionVideosMap[
                                    key
                                ].OnDemand.items?.findIndex(
                                    (item) =>
                                        item.broadcast?.details?.id ===
                                        broadcast.details?.id
                                ) ?? -1;

                            if (existingIndex >= 0) {
                                // console.log(
                                //     "Update existing live/ondemand video",
                                //     state.collectionVideosMap[key].OnDemand
                                //         .items![existingIndex]!,
                                //     broadcast
                                // );
                                // Update existing item
                                state.collectionVideosMap[key].OnDemand.items![
                                    existingIndex
                                ]! = {
                                    ...state.collectionVideosMap[key].OnDemand
                                        .items![existingIndex],
                                    broadcast: broadcast
                                };
                            }
                            return false; // Remove from processing regardless of whether it was in OnDemand
                        }
                        return true; // Keep in processing
                    }
                );

                //console.log("Current Processing List", stillProcessing);

                state.collectionVideosMap[key].Processing = stillProcessing;
            }
        );

        // Single Broadcast Fullfilled
        builder.addCase(
            getSingleBroadcastResponse.fulfilled,
            (state, action) => {
                state.singleBroadcastResponse = action.payload;
                state.hasLoadedSingleBroadcastResponse = true;
            }
        );

        // Single Broadcast Rejected
        builder.addCase(getSingleBroadcastResponse.rejected, (state) => {
            state.hasLoadedSingleBroadcastResponse = true;
            state.hasLoadingError = true;
        });
    }
});

export const {
    setConfiguredCatalogId,
    setConfiguredCollectionId,
    setConfiguredBroadcastId,
    setCatalogDetails,
    setCollectionDetails,
    clearCatalog,
    setIsGatedContentDisabled,
    setHasLoadedSingleBroadcastResponse
} = catalogState.actions;

export default catalogState.reducer as Reducer<CatalogState>;
