import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { RootState } from "store/reducers";

// Shared empty array for referential stability
const EMPTY_ARRAY: readonly any[] = Object.freeze([]);

// Selectors
const selectCollections = (state: RootState) =>
    state.catalogState.collections.collections;
const selectCollectionVideosMap = (state: RootState) =>
    state.catalogState.collectionVideosMap;
const selectHasLoadedOnDemand = (state: RootState) =>
    state.catalogState.hasLoadedCollectionVideosOnDemand;
const selectHasLoadedUpcoming = (state: RootState) =>
    state.catalogState.hasLoadedCollectionVideosUpcoming;

// First select the collection
const selectCollection = createSelector(
    [selectCollections],
    (collections) => collections?.[0]
);

// Select the collection ID
const selectCollectionId = createSelector(
    [selectCollection],
    (collection) => collection?.details?.id ?? ""
);

// Select videos for the collection
const selectCollectionVideos = createSelector(
    [selectCollectionVideosMap, selectCollectionId],
    (collectionVideosMap, collectionId) => collectionVideosMap?.[collectionId]
);

// Select the OnDemand videos
const selectOnDemandVideos = createSelector(
    [selectCollectionVideos],
    (collectionVideos) => {
        const videos = collectionVideos?.OnDemand?.items;
        if (!videos) return EMPTY_ARRAY;

        const filteredVideos = videos.filter((item) => !!item);
        // Return empty array reference if no videos
        if (filteredVideos.length === 0) return EMPTY_ARRAY;

        return filteredVideos;
    }
);

// Select the Upcoming videos
const selectUpcomingVideos = createSelector(
    [selectCollectionVideos],
    (collectionVideos) => {
        const videos = collectionVideos?.Upcoming?.items;
        if (!videos) return EMPTY_ARRAY;

        const filteredVideos = videos.filter((item) => !!item);
        // Return empty array reference if no videos
        if (filteredVideos.length === 0) return EMPTY_ARRAY;

        return filteredVideos;
    }
);

// Select the Processing videos
const selectProcessingVideos = createSelector(
    [selectCollectionVideos],
    (collectionVideos) => {
        const videos = collectionVideos?.Processing;
        if (!videos) return EMPTY_ARRAY;

        const filteredVideos = videos.filter((item) => !!item);
        // Return empty array reference if no videos
        if (filteredVideos.length === 0) return EMPTY_ARRAY;

        return filteredVideos;
    }
);

// Select the loading state
const selectHasLoaded = createSelector(
    [selectHasLoadedOnDemand, selectHasLoadedUpcoming],
    (hasLoadedOnDemand, hasLoadedUpcoming) =>
        hasLoadedOnDemand && hasLoadedUpcoming
);

// Create a combined array selector that's referentially stable
const selectAllVideos = createSelector(
    [selectOnDemandVideos, selectUpcomingVideos],
    (onDemandVideos, upcomingVideos) => {
        if (onDemandVideos.length === 0 && upcomingVideos.length === 0) {
            return EMPTY_ARRAY;
        }
        return [...upcomingVideos, ...onDemandVideos];
    }
);

// Combined selector
const selectCollectionAndVideos = createSelector(
    [
        selectCollection,
        selectHasLoaded,
        selectOnDemandVideos,
        selectUpcomingVideos,
        selectAllVideos,
        selectProcessingVideos
    ],
    (
        collection,
        hasLoaded,
        onDemandVideos,
        upcomingVideos,
        allVideos,
        processingVideos
    ) => ({
        collection,
        hasLoaded,
        onDemandVideos,
        upcomingVideos,
        allVideos,
        processingVideos
    })
);

const useCollectionWithVideos = () => {
    return useSelector(selectCollectionAndVideos);
};

export default useCollectionWithVideos;
