import classNames from "classnames/bind";
import styles from "./index.module.scss";
const cx = classNames.bind(styles);

export interface ButtonProps {
    text: string;
    onClick: () => void;
    disabled?: boolean;
    type?:
        | "button"
        | "link"
        | "outline"
        | "floating"
        | "large"
        | "video"
        | "collection";
    className?: string;
    isOverlay?: boolean;
    isVertical?: boolean;
}

export const Button = ({
    text,
    onClick,
    disabled = false,
    type = "button",
    className,
    isOverlay = false,
    isVertical = false
}: ButtonProps) => (
    <button
        className={cx(className, {
            "btn btn-outline w-100": type === "outline",
            "btn btn-generic-overlay": type === "button" && isOverlay,
            "btn btn-primary w-100": type === "button" && !isOverlay,
            "btn btn-primary": type === "large" && !isOverlay,
            "btn w-100": type === "floating",
            [styles[type]]: type !== "button" && type !== "outline",
            [styles["vertical"]]: type === "video" && isVertical
        })}
        id={text}
        name={text}
        onClick={() => {
            onClick();
        }}
        disabled={disabled}
    >
        {type === "button" ? text.toUpperCase() : text}
    </button>
);
