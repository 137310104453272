import { Client as SwitcherClient } from "@switcherstudio/player-api-client";
import store from "store/store";

export class Client extends SwitcherClient {
    _http: { fetch(url: RequestInfo, init?: RequestInit): Promise<Response> };

    constructor(
        http: {
            fetch(url: RequestInfo, init?: RequestInit): Promise<Response>;
        } = {
            fetch: async (
                url: RequestInfo,
                init?: RequestInit | undefined
            ): Promise<Response> => {
                const state = store.getState();
                const scopedBearerToken =
                    state.creatorCustomers[
                        state.playerCreator?.details?.projectId ?? ""
                    ]?.ticket?.access_token;

                const scopedPasswordClaims =
                    state.creatorCustomers[
                        state.playerCreator?.details?.projectId ?? ""
                    ]?.passwordClaims ?? [];

                if (init && init.headers) {
                    if (scopedBearerToken) {
                        (init.headers as { [key: string]: string })[
                            "Authorization"
                        ] = "Bearer " + scopedBearerToken;
                    }
                    if (scopedPasswordClaims.length > 0) {
                        (init.headers as { [key: string]: string })[
                            "X-Gated-Password-Access"
                        ] = JSON.stringify(scopedPasswordClaims);
                    }

                    // Add no-cache header if referrer is from switcherstudio.com or localhost
                    const referrer = state.videoSession?.referrerUrl;
                    if (referrer) {
                        try {
                            const referrerHost = new URL(referrer).host;
                            if (
                                /^dashboard(-.*)?\.switcherstudio\.com$/.test(
                                    referrerHost
                                ) ||
                                referrerHost === "localhost"
                            ) {
                                (init.headers as { [key: string]: string })[
                                    "Cache-Control"
                                ] = "no-cache";
                            }
                        } catch (e) {
                            // Invalid referrer URL, ignore
                        }
                    }
                }

                const request = fetch(url, init);
                return request;
            }
        }
    ) {
        // TODO: Run this through the api-proxy
        super(
            import.meta.env.VITE_ENV === "local" ||
                import.meta.env.VITE_ENV === "dev"
                ? import.meta.env.VITE_PLAYER_API_URL ||
                      "https://vapi-develop.switcherstudio.com"
                : `${window.location.protocol}//${window.location.host}/api-proxy`,
            http
        );
        this._http = http;
    }
}

// Just export this so we don't have to construct new ones all over the place
// unless custom options are required.
export const client = new Client();
